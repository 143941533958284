import { httpClient } from './httpClient'
import { ContactUs } from '@/models/ContactUs'

const contactUsRoute = 'contact'

const contactUs = async (contact: ContactUs): Promise<void> => {
  (await httpClient.post(contactUsRoute, { ...contact })).data
}

export { contactUs }
