
import { defineComponent, ref } from 'vue'
import httpClientGraphql from '@/kentico-api/httpClientGraphql'
import CkeditorModule from '@/components/CkeditorModule.vue'

export default defineComponent({
  components: {
    CkeditorModule
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    featured: Boolean
  },
  setup (props) {
    const modalContent = ref()
    const loading = ref(true)
    const error = ref()
    const getEmbedTextQuery = {
      query: `query getEmbedTextQuery($slug: String) {
        body: embedText_All(
          where:
          {
            AND: [
              {slug: {eq: $slug}}
            ]
          }
        ) {
          items {
            slug
            title
            bodyCkeditor {
              value
            }
          }
        }
      }`
    }
    httpClientGraphql
      .post('', {
        query: getEmbedTextQuery.query,
        variables: {
          slug: props.slug
        }
      })
      .then(({ data }) => {
        const [firstItem] = data.data.body.items
        modalContent.value = firstItem
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
    return {
      showModal: ref(false),
      modalContent,
      loading,
      error
    }
  }
})
